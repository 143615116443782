import { combineReducers } from "redux";

export default combineReducers({
  account: require("./account").reducer,
  onGoingRides: require("./transporter/transporter-ongoing-rides").reducer,
  historyRides: require("./transporter/transporter-rides-history").reducer,
  transporterVehicles: require("./transporter/transporter-vehicle").reducer,
  transporterPartners: require("./transporter/transporter-partner").reducer,
  driverLocation: require("./ride/driver-location").reducer,
  bilans: require("./bilan").reducer,
  newRide: require("./ride/new-ride").reducer,
  updateRide: require("./ride/update-ride").reducer,
  minio: require("./minio").reducer,
  setting: require("./setting").reducer,
  dashboard: require("./dashboard").reducer,
  planning: require("./planning").reducer,
});

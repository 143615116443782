import { Component } from "react";
import { HStack, VStack, Box, Pressable, Menu } from "native-base";
import moment from "moment";
import { Tag, Text, Button } from "components";
import _ from "lodash";
import { getDangerMessage } from "functions/bilan";
import { getBackendUrl } from "functions";
import clockIcon from "assets/img/clock.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faPlus,
  faEdit,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import DownloadShowButton from "components/DownloadShowButton";
import InputAddress from "components/InputAddress";

function formatWaitingTime(waitingTime, status) {
  return ["onway", "onboard"].includes(status) &&
    !_.isEmpty(waitingTime) &&
    _.isInteger(waitingTime.duration_in_traffic)
    ? moment.utc(waitingTime.duration_in_traffic * 1000).format("HH:mm")
    : "--:--";
}

class OnGoingRidesCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowMoreInfo: false,
      selectDropoff: null,
    };

    this.onRideShowMoreInfoToggle = this.onRideShowMoreInfoToggle.bind(this);
    this.onPressShowMap = this.onPressShowMap.bind(this);
    this.downloadBilanAndShow = this.downloadBilanAndShow.bind(this);
    this.downloadOtherFiles = this.downloadOtherFiles.bind(this);
    this.saveDropoff = this.saveDropoff.bind(this);
  }

  onPressShowMap() {
    this.props.onShowMap(this.props.ride);
  }

  onRideShowMoreInfoToggle() {
    this.setState(
      Object.assign({}, this.state, {
        isShowMoreInfo: !this.state.isShowMoreInfo,
      })
    );
  }

  downloadOtherFiles(filesAvailable) {
    const { patient } = this.props.ride;
    this.props.downloadFiles(filesAvailable, patient);
  }

  downloadBilanAndShow(show = false) {
    const { _id } = this.props.ride;
    const urlBilan = `${getBackendUrl()}/downloads/bilan/${_id}/${
      show ? "show" : ""
    }?token=${this.props.token}`;
    window.open(urlBilan, "_blank");
  }

  saveDropoff() {
    this.props.updateDropoffRequest(
      this.props.ride._id,
      this.state.selectDropoff
    );
  }

  render() {
    const { t, i18n } = this.props;
    const {
      rideNumber,
      patient,
      status,
      dropoff,
      pickup,
      transporter,
      datetime,
      intervention,
      observation,
      subject,
      bilan = null,
      waitingTime = {},
    } = this.props.ride;
    const rideAssigned = transporter !== null;
    const statusColor = `status.${status}`;
    const textStatusColor = `textStatus.${status}`;
    // const statusText = t(`rideStatus.${status}`, { lng: i18n.language });
    const { isShowMoreInfo } = this.state;
    const messages = bilan ? getDangerMessage(bilan) : null;
    // const isDanger = messages != "" && messages != null;
    // const bilanCompleted = bilan ? bilan.status == "completed" : false;
    const canHaveBilan = !["incoming", "pending", "onway"].includes(status);
    const rideStarted = !["incoming", "pending"].includes(status);
    const filesAvailable = bilan?.documents
      ? Object.values(bilan.documents).filter((e) => e !== null)
      : [];

    function NotSpecified() {
      return (
        <Text fontSize="12px" italic>
          {t("TransporterReservationsPage.not_specified", {
            lng: i18n.language,
          })}
        </Text>
      );
    }

    return (
      <Pressable
        onPress={this.onRideShowMoreInfoToggle}
        mb={this.props.mb || 0}
        position="relative"
        borderWidth={1}
        boxSize="border-box"
        rounded="4px"
        borderColor={rideAssigned ? "gray" : "status.unassigned"}
        _hover={{ borderColor: "rgba(2, 150, 174, 0.6)" }}
        _pressed={{ borderColor: "#0296AE" }}
        display="flex"
        flexDirection="row"
        alignItems="center"
        maxWidth="986px"
        py="2"
        px="27px"
        bg="ligth"
      >
        <HStack w="full">
          <VStack space={4} w="295px" pr={2}>
            <HStack space="40px">
              <VStack space={5} h="110px">
                <Text fontSize="16px" fontWeight={900}>
                  {rideNumber}
                </Text>
                <Text fontSize="12px" fontWeight={500}>
                  {moment(datetime).format("DD MMM HH:mm")}
                </Text>
                <HStack justifyContent="space-between" w="full">
                  <img src={clockIcon} style={{ width: 25 }} alt="" />
                  <Text fontSize="16px" fontWeight={900}>
                    {formatWaitingTime(waitingTime, status)}
                  </Text>
                </HStack>
              </VStack>

              <VStack space={2} flex={1}>
                <VStack>
                  <Text fontSize="12px" fontWeight="500" color="gray">
                    Prise en charge
                  </Text>
                  <Text fontSize="12px" fontWeight="500" numberOfLines={2}>
                    {pickup.address}
                  </Text>
                </VStack>
                <VStack>
                  <HStack space={2} alignItems={"center"}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Dépose
                    </Text>

                    {/* button add/edit dropoff address */}
                    <Menu
                      trigger={(triggerProps) => (
                        <Pressable
                          accessibilityLabel="More options menu"
                          {...triggerProps}
                        >
                          <FontAwesomeIcon
                            size={"sm"}
                            icon={dropoff.address ? faEdit : faPlus}
                            style={{ color: "rgb(95, 174, 232)" }}
                          />
                        </Pressable>
                      )}
                    >
                      <HStack
                        px="2"
                        py="3"
                        space={2}
                        h={270}
                        maxH={400}
                        alignItems="start"
                      >
                        <InputAddress
                          onSelectAddress={(prop) =>
                            this.setState(
                              Object.assign({}, this.state, {
                                selectDropoff: prop,
                              })
                            )
                          }
                        />

                        <Pressable
                          onPress={this.saveDropoff}
                          borderWidth={1}
                          rounded={4}
                          p={2}
                          borderColor={
                            this.state.selectDropoff ? "primary" : "gray"
                          }
                          disabled={this.state.selectDropoff === null}
                        >
                          <FontAwesomeIcon
                            size={"lg"}
                            icon={faSave}
                            style={{
                              color: this.state.selectDropoff
                                ? "rgb(95, 174, 232)"
                                : "gray",
                            }}
                          />
                        </Pressable>
                      </HStack>
                    </Menu>
                  </HStack>

                  {dropoff.address ? (
                    <Text
                      nbOfLines={2}
                      fontSize="12px"
                      fontWeight="500"
                      numberOfLines={2}
                    >
                      {dropoff.address}
                    </Text>
                  ) : (
                    <NotSpecified />
                  )}
                </VStack>
              </VStack>
            </HStack>

            {isShowMoreInfo && (
              <VStack space={1} pt={2} borderTopWidth={1} borderColor="gray">
                <Text fontSize="12px" fontWeight="600" color="gray">
                  Informations complémentaires du lieu d'intervention
                </Text>

                <HStack space={4} w="full">
                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Batiment
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.batiment}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Digit Code
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.digicode}
                    </Text>
                  </VStack>

                  <VStack flex={1}>
                    <Text fontSize="12px" fontWeight="500" color="gray">
                      Étage
                    </Text>
                    <Text fontSize="12px" fontWeight="500" isTruncated>
                      {intervention.etage}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )}
          </VStack>

          <HStack w="264px">
            <VStack
              space={1}
              pl={4}
              borderLeftWidth={1}
              borderColor="gray"
              flex={1}
            >
              <Text
                fontSize="16px"
                fontWeight={900}
                isTruncated={!isShowMoreInfo}
              >
                {`${patient.firstname} ${patient.lastname}`}
              </Text>
              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Age
                </Text>
                {patient?.age ? (
                  <Text
                    fontSize="12px"
                    fontWeight={900}
                  >{`${patient.age} ans`}</Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>

              <VStack>
                <Text fontSize="12px" fontWeight={500} color="gray">
                  Motif
                </Text>
                {subject ? (
                  <Text fontSize="12px" fontWeight={900}>
                    {subject}
                  </Text>
                ) : (
                  <NotSpecified />
                )}
              </VStack>
              {isShowMoreInfo && (
                <VStack space={1}>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° téléphone
                    </Text>
                    {patient?.phone ? (
                      <Text fontSize="12px" fontWeight={500}>
                        {patient.phone}
                      </Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight={500} color="gray">
                      N° sécurité sociale
                    </Text>
                    {patient?.nss ||
                    bilan?.administrative?.socialSecurity?.nss ? (
                      <Text fontSize="12px" fontWeight={500}>
                        {patient?.nss ||
                          bilan?.administrative?.socialSecurity?.nss}
                      </Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" fontWeight="500" mb="2px" color="gray">
                      Observation
                    </Text>
                    {observation ? (
                      <Text numberOfLines={3}>{observation}</Text>
                    ) : (
                      <NotSpecified />
                    )}
                  </VStack>
                </VStack>
              )}
            </VStack>

            <VStack flex={1} justifyContent="space-between">
              <VStack>
                <Text fontSize="12px" fontWeight="500" mb="2px" color="gray">
                  Alertes
                </Text>
                {messages &&
                  messages.map((e, index) => {
                    if (!isShowMoreInfo && index > 3) return null;
                    if (!isShowMoreInfo && index === 3)
                      return (
                        <Text
                          fontSize="12px"
                          fontWeight="900"
                          color="danger"
                          key={e}
                        >
                          ...
                        </Text>
                      );
                    return (
                      <Text
                        fontSize="12px"
                        fontWeight="900"
                        color="danger"
                        key={e}
                      >
                        {e}
                      </Text>
                    );
                  })}
              </VStack>
            </VStack>
          </HStack>

          <VStack
            space={1}
            flex={1}
            pl={4}
            borderLeftWidth={1}
            borderColor="gray"
          >
            <HStack space="44px">
              <VStack space={1} w="132px">
                <Text fontSize="12px" fontWeight="500" color="gray">
                  Véhicule
                </Text>
                {status === "incoming" && <NotSpecified />}
                <Text fontSize="16px" fontWeight="900">
                  {transporter?.company?.name || ""}
                </Text>
                <Text fontSize="12px" fontWeight="700">
                  {transporter?.vehicle?.plate.toUpperCase() || ""}
                </Text>
                <Text fontSize="12px" fontWeight="500">
                  {transporter?.vehicle?.phone || ""}
                </Text>
              </VStack>

              <Box flex={1}>
                <VStack space={2} alignItems="flex-start">
                  <Tag
                    text={
                      "● " +
                      t(`Common:Ride.Status.${status}`, {
                        lng: i18n.language,
                      })
                    }
                    color={textStatusColor}
                    bg={statusColor}
                  />
                  {!canHaveBilan && (
                    <DownloadShowButton
                      label="Fiche bilan"
                      isDanger={
                        messages !== "" &&
                        messages !== null &&
                        messages.length > 0
                      }
                      onShow={() => this.downloadBilanAndShow(true)}
                      onDownload={() => this.downloadBilanAndShow(false)}
                    />
                  )}
                  {filesAvailable.length > 0 && (
                    <DownloadShowButton
                      label="Autres documents"
                      hideShow={true}
                      onDownload={this.downloadOtherFiles}
                    />
                  )}
                  <Button
                    third
                    color={rideStarted ? "default" : "gray"}
                    borderColor={rideStarted ? "default" : "gray"}
                    py="1px"
                    px="10px"
                    borderWidth="1px"
                    onPress={this.onPressShowMap}
                    disabled={!rideStarted}
                  >
                    {t("TransporterReservationsPage.button_localisation", {
                      lng: i18n.language,
                    })}
                  </Button>
                  {/* TRASH ACTION */}
                  {isShowMoreInfo && (
                    <HStack justifyContent="flex-end" w="full">
                      <Pressable
                        size={10}
                        rounded="full"
                        alignItems="center"
                        justifyContent="center"
                        bg="white"
                        onPress={() =>
                          this.props.onOpenEdit(this.props.ride)
                        }
                      >
                        <FontAwesomeIcon
                          size={15}
                          icon={faEdit}
                          style={{ color: "#5FAEE8" }}
                        />
                      </Pressable>
                      <Pressable
                        size={10}
                        rounded="full"
                        alignItems="center"
                        justifyContent="center"
                        bg="white"
                        onPress={() =>
                          this.props.onOpenTrashRideModal(this.props.ride)
                        }
                      >
                        <FontAwesomeIcon
                          size={15}
                          icon={faBan}
                          style={{ color: "#FF1700" }}
                        />
                      </Pressable>
                    </HStack>
                  )}
                </VStack>
              </Box>
            </HStack>
          </VStack>
        </HStack>
      </Pressable>
    );
  }
}

export default OnGoingRidesCard;

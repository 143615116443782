import { put, takeLatest } from "redux-saga/effects";
import UpdateRideActions, { UpdateRideTypes } from "reducers/ride/update-ride";
import _ from "lodash";

const graphQLClient = () => {
  const { Store } = require("../../store");
  const state = Store.getState();
  const account = state.account.asMutable({ deep: true });
  const { token } = account;

  const { graphQLClient } = require("../../graphql");
  return graphQLClient.setHeaders({ authorization: `Bearer ${token}` });
};

function* samuUpdateRideAsyncRequest({ ride, callback, rideId }) {
  const query = `
    mutation{
      SamuUpdateRide(
      rideId: "${rideId}",
      ${ride.join(",")}
      )
    }
  `;
  try {
    const { SamuUpdateRide } = yield graphQLClient().request(query);

    if (SamuUpdateRide) {
      yield put(UpdateRideActions.samuUpdateRideRequestSuccess());
      if (_.isFunction(callback)) callback();
    } else {
      yield put(UpdateRideActions.samuUpdateRideRequestFailure("Server error"));
    }
  } catch (error) {
    yield put(UpdateRideActions.samuUpdateRideRequestFailure("Server error"));
  }
}

/* ------------- Connect Types To Sagas ------------- */
// eslint-disable-next-line
export default [
  takeLatest(
    UpdateRideTypes.SAMU_UPDATE_RIDE_REQUEST,
    samuUpdateRideAsyncRequest
  ),
];

import { all } from "redux-saga/effects";
import AccountSaga from "./account";
import RidesHistory from "./transporter/transporter-rides-history";
import OnGoingRides from "./transporter/ongoing-rides";
import TransporterVehicle from "./transporter/transporter-vehicle";
import TransporterPartner from "./transporter/transporter-partner";
import DriverLocation from "./ride/driver-location";
import BilansSagas from "./bilan";
import NewRide from "./ride/new-ride";
import UpdateRide from "./ride/update-ride";
import Minio from "./minio";
import Setting from "./setting";
import Dashboard from "./dashboard";
import Planning from "./planning";

// eslint-disable-next-line
export default function* root() {
  yield all([
    ...AccountSaga,
    ...RidesHistory,
    ...OnGoingRides,
    ...TransporterVehicle,
    ...DriverLocation,
    ...TransporterPartner,
    ...BilansSagas,
    ...NewRide,
    ...UpdateRide,
    ...Minio,
    ...Setting,
    ...Dashboard,
    ...Planning,
  ]);
}
